<template>
	<div class="modal-mask">
		<div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-body pa-3" v-click-outside="onClickOutside">
		      <transition name="fadeStop">
	          <form @submit.prevent>
	          	<div class="flex justify-space-between align-center mb-2">
	            	<h2>Payroll Help Form</h2>

				         	<i class="fas fa-times mr-1" @click="$emit('close')"></i>

				      </div>
				      <div class="mb-3">
				      	<h4>Please complete the form below so our team can research your issue and get it resolved. Expect a response in 24-48 business hours.</h4>
				      </div>
	            <div class="mb-3">
	              <label for="name">What is your first and last name, as you would have signed in on the timesheets?</label>
	              <input v-model.trim="payrollForm.name" type="text" placeholder="" id="name" required />
	            </div>
	            <div class="mb-3">
	              <label for="phone">What is your cell phone number?</label>
	              <input v-model.trim="payrollForm.phone" type="text" v-mask="'(###) ###-####'" placeholder="(###) ###-####" id="phone" required />
	            </div>
	            <div class="mb-3">
					      <label>What event are you inquiring about?</label>
					      <input v-model.trim="payrollForm.event" type="text" placeholder="" id="event" required />
					    </div>
					    <div class="mb-3">
	              <label for="manager">Who was the manager you spoke with? </label>
	              <input v-model.trim="payrollForm.manager" type="text" placeholder="" id="manager" />
	            </div>
	            <div class="mb-3">
	              <label for="summary">Please provide a summary of your issue:</label>
	              <textarea v-model.trim="payrollForm.summary" name="summary" id="" cols="30" rows="6" required></textarea>
	            </div>

	            <button :disabled="!valid" @click="submit()" class="btn btn__accent btn__large mt-2">Submit
	            	<transition name="fade">
                  <span class="ml-2" v-if="performingRequest">
                  <i class="fa fa-spinner fa-spin"></i>
                  </span>
                </transition>
	            </button>
	          </form>
		      </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped type="text/css">
  .signup-form {
    width: 96%;
    max-width: 100%;
    margin: auto;
}
</style>

<script>
import { mapState } from 'vuex'
const fb = require('../firebaseConfig.js')

export default {
  data() {
    return {
    	performingRequest:false,
      payrollForm: {
        name: '',
        phone: '',
        event: '',
        manager: '',
        summary: ''
      },
    }
  },
  created () {
    if (!this.events || this.events.length < 1) {
      this.$store.dispatch("getEvents")
    }
  },
  computed: {
    ...mapState(['currentUser', 'events', 'userProfile']),
    valid() {
      if ((this.payrollForm.name.length > 6) && (this.payrollForm.phone.length > 8) && (this.payrollForm.event) && (this.payrollForm.summary)) {
        return true
      }
      else {
        return false
      }
    }
  },
  methods: {
  	onClickOutside(){
			this.$emit('close')
  	},
    submit() {
    	this.performingRequest = true
      this.$store.dispatch('pFormSubmit', {
      	user: this.userProfile,
        name: this.payrollForm.name,
        phone: this.payrollForm.phone,
        event: this.payrollForm.event,
        manager: this.payrollForm.manager,
        summary: this.payrollForm.summary,
      })
      setTimeout(() => {
      	this.performingRequest = false
        this.$emit('close')
      }, 1000)
    }
  	
  },
}
</script>
