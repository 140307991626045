<template>
	<div class="whiteBack mb-5" :class="{ invalid: (!userProfile.firstName || !userProfile.lastName) }">
		<div class="mb-3">
      <label for="firstName">First Name</label>
      <input type="text" autocomplete="given-name" placeholder="" v-model.trim="userProfile.firstName" id="firstName" name="firstName" @change="updateProfile()" required/>
    </div>
    <div class="mb-3">
      <label for="lastName">Last Name</label>
      <input type="text" autocomplete="family-name" placeholder="" v-model.trim="userProfile.lastName" id="lastName" name="lastName" @change="updateProfile()" required/>
    </div>
  
	</div>
</template>

<script>
const fb = require('../../firebaseConfig.js')
import { mapState } from 'vuex'

export default {
  props: ['userProfile'],
  
  computed: {
     ...mapState([ 'currentUser']),
  },
  methods: {
    updateProfile(){
    	let userProfile = this.userProfile
    	this.$store.dispatch('updateUser', userProfile)
    },
  }
}
</script>