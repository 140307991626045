<template>
  <div class="dashboard">
    <div class="dashboard__container">
      
      <Loader v-if="!userProfile || !userProfile.id" />
      
      <div class="dashboard__container--body pt-2" v-if="userProfile && userProfile.id">
        
        <div class="dashboard__container--body--col" v-if="userProfile && !userProfile.payrollSubmitted">
          <ProfileContact :userProfile="userProfile" :lout="true" :h2="true" />
        </div>

        <!-- <div class="dashboard__container--body--col">
          <MyAssignments :shifts="upcomingShifts" />
        </div> -->
        <div class="dashboard__container--body--col">
          <WhatsNext />
        </div>
        
        <!-- <div class="dashboard__container--body--col">
          <Contact />
        </div> -->
        
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import ProfileContact from '@/components/Profile/ProfileContact.vue'
import Contact from '@/components/Contact.vue'
import WhatsNext from '@/components/WhatsNext.vue'
// import MyAssignments from '@/components/MyAssignments.vue'
// import MyPreferred from '@/components/MyPreferred.vue'

export default {
  name: 'dashHome',
  // created () {
  //   // this.$store.dispatch("getUpcomingAssignments");
  //   // if (!this.userProfile) {
  //   //   this.$store.dispatch("getUserProfile");
  //   // }
  // },
  // mounted () {
  //   this.$store.dispatch("getInfiniteEvents")
  //   this.$store.dispatch("getVenues")
  // },
  computed: {
    ...mapState(['currentUser', 'userProfile']),
    // upcomingShifts() {
    //   return this.upcomingAssignments.slice(0, 3);
    // },
  },
  components: {
    Loader,
    WhatsNext,
    // MyAssignments,
    // MyPreferred,
    Contact,
    ProfileContact
  },
  
  beforeDestroy () {
    console.log(this)
    this.$store.dispatch('clearErrors')
    this.$store.dispatch("clearDashState")
  }
}
</script>

<style lang="scss" scoped>

</style>