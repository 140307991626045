<template>
  <div>
    <div class="mb-3 flex align-center justify-space-between">
    <h2>What's Next:</h2>
    
    </div>
    <div>
      <div class="mb-4">
        <router-link :to="{name: 'profile'}">
          <button class="btn btn__outlined">Update Your Profile<i class="fas fa-chevron-right ml-3"></i></button>
        </router-link>
      </div>
      <div class="mb-4">
        <router-link :to="{name: 'events'}">
          <button class="btn btn__outlined">Browse Upcoming Events<i class="fas fa-chevron-right ml-3"></i></button>
        </router-link>
      </div>
      <div class="mb-4">
        <router-link :to="{name: 'venues'}">
          <button class="btn btn__outlined">Follow Your Favorite Venues<i class="fas fa-chevron-right ml-3"></i></button>
        </router-link>
      </div>
      <!-- <div class="mb-3">
        <a  href="https://apps.apple.com/us/app/mvp-event-staffing/id1591881518">
          <img src="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/appimg.png?alt=media&token=c932fffe-48f2-43e6-8b12-f984854dcafd" alt="" style="width:180px;">
        </a>
      </div>
      <div class="mb-3">
        <a  href="https://play.google.com/store/apps/details?id=com.kptech80.mvpeventstaffing">
          <img src="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/andimg.png?alt=media&token=4506d39d-bcd6-4d3f-b091-62699aad79a3" alt="" style="width:180px;">
        </a>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import router from '@/router'

export default {
  data: () => ({
    columns: [
      {
        label: 'Title',
        field: 'title',
      },
      // {
      //   label: 'Start',
      //   field: 'startDate',
      // },
      // {
      //   label: 'End',
      //   field: 'endDate',
      // },
      {
        label: 'Venue',
        field: 'venue.title',
      },
      {
        label: 'City',
        field: 'venue.address.city',
      },
      // {
      //   label: 'State',
      //   field: 'venue.address.state',
      // },
    ]
  }),
  computed: {
    firstEvents() {
      return this.events.reverse().slice(0, 3)
    },
  },
  methods: {
    onRowClick(params) {
      let url = `/events/` + params.row.slug
      console.log(url)
      router.push(url)
    }
  },
  // created () {
  //   if (!this.events || this.events.length < 1) {
  //     this.$store.dispatch("getEvents")
  //   }
  // },
  // beforeDestroy () {
  //   this.$store.dispatch("clearEventsState")
  // }
}
</script>