<template>
	<div>
		<div class="mb-2">
    	<h2 style="border-bottom:1px solid #eee; padding-bottom:0.25rem; margin-bottom:0.5rem;">Contact Us:</h2>
    </div>
    <h3>Payroll Department</h3>
    <p>If you have questions about getting paid from an event, a specific paycheck, or any other payroll questions, please submit this form. This is the QUICKEST way to get your issue resolved.</p>
    <!-- <p>Phone: <a href="tel:415-294-1925">415-294-1925</a><br />
    Email: <a href="mailto:payroll@mvpeventstaffing.com">payroll@mvpeventstaffing.com</a></p> -->
    <button v-if="currentUser" class="btn btn__outlined mt-3 mr-5" @click="rModal()">Payroll Help Form</button>
    <transition name="modal">
      <div v-if="payrollForm">
        <PForm @close="closeModal()" />
      </div>
    </transition>
    <div class="mb-3"></div>
    <h3>Staff Support</h3>
    <p>If you have questions about the website, applications, your account, or any other general questions, please reach out to Staff Support.</p>
    <p>Phone: <a href="tel:415-390-6365">415-390-6365</a><br />
    Email: <a href="mailto:support@mvpeventstaffing.com">support@mvpeventstaffing.com</a></p>
    <div class="mb-3"></div>
    
    <h3>Human Resources</h3>
    <p>If you have any questions about your OnPay setup, your employment status, or any other HR issues, please contact HR</p>
    <p>Phone: <a href="tel:415-390-6365">415-390-6365</a><br />
    Email: <a href="mailto:support@mvpeventstaffing.com">support@mvpeventstaffing.com</a></p>
    <p>Phone: <a href="tel:510.822.2080">510.822.2080</a><br />
    Email: <a href="mailto:hr@mvpeventstaffing.com">hr@mvpeventstaffing.com</a></p>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PForm from '@/components/PForm.vue'

export default {
  data: () => ({
    performingRequest: false,
    payrollForm: false
  }),
  components: {
    PForm
  },
  computed: {
    ...mapState(['currentUser']),
    valid() {
      if ((this.form.email && this.form.email.length > 6) && (this.form.firstname) && (this.form.lastname) && (this.form.phone) && (this.form.details)) {
        return true
      }
      else {
        return false
      }
    },
  },
  methods: {
    rModal() {
      this.payrollForm = true
    },
    closeModal() {
      this.payrollForm = false
    },
  }
}

</script>

